<template>
  <v-dialog
    v-model="light"
    max-width="500px"
    :persistent="true"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Record new Class</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="title"
                label="Title"
                name="Title"
                v-validate="'required'"
              ></v-text-field>
              <span style="color: red;"
                v-show="errors.has('Title')" v-if="valid">{{ errors.first('Title') }}</span>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-select
                v-model="mode"
                :items="[{text:'Day',value:0},{text:'Night',value: 1}]"
                label="Mode"
                name="Mode"
                v-validate="'required'"
              ></v-select>
              <span style="color: red;"
                    v-show="errors.has('Mode')" v-if="valid">{{ errors.first('Mode') }}</span>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-select
                v-model="dept"
                label="select department"
                name="Department"
                v-validate="'required'"
                @click="fetchSelectedDepartments"
                :items="departments"
              ></v-select>
              <span style="color: red;"
                v-show="errors.has('Department')" v-if="valid">{{ errors.first('Department') }}</span>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-select
                v-model="level"
                label="select level"
                name="Levels"
                v-validate="'required'"
                :items="levels"
              ></v-select>
              <span style="color: red;"
                v-show="errors.has('Levels')" v-if="valid">{{ errors.first('Levels') }}</span>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-select
                v-model="mentor"
                label="select mentor"
                name="Mentor"
                v-validate="'required'"
                :items="mentors"
                @click="fetchSelectedMentors"
              ></v-select>
              <span style="color: red;"
                v-show="errors.has('Mentor')" v-if="valid">{{ errors.first('Mentor') }}</span>
            </v-col>
            <v-col cols="12">
              <v-text-field
                name="Capacity"
                label="Accomodation Capacity"
                v-model="capacity"
                type="text"
                v-validate="'required|numeric'"
              >
              </v-text-field>
              <span style="color: red;"
                    v-show="errors.has('Capacity')" v-if="valid">{{ errors.first('Capacity') }}</span>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="offModal"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'add-class',
  props: {
    light: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      logger: [],
      header: '',
      title: '',
      mentor: '',
      dept: '',
      level: '',
      mode: '',
      levels: [{ text: 'Level 1', value: 1 },
        { text: 'Level 2', value: 2 },
        { text: 'Level 3', value: 3 },
        { text: 'Level 4', value: 4 },
        { text: 'Level 5', value: 5 }],
      departments: [],
      mentors: [],
      valid: true,
      capacity: '',
    }
  },
  created () {
    // this.fetchSelectedLevels()
    this.fetchSelectedDepartments()
    this.fetchSelectedMentors()
  },
  methods: {
    offModal: function () {
      this.title = ''
      this.dept = ''
      this.mentor = ''
      this.level = ''
      this.valid = false
      this.$emit('closeModal', false)
    },
    async validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
        } else {
          // form have errors
        }
      })
    },
    async save () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      if (this.title === '' || this.dept === '' || this.mentor === '' || this.level === '') {
        this.validate()
      } else {
        const data = {
          title: this.title,
          department: this.dept,
          mentor: this.mentor,
          level: this.level,
          mode: this.mode,
          id: this.id,
          capacity: this.capacity,
        }
        try {
          const res = await this.axios({
            url: 'manipulate_class',
            method: 'post',
            data: data,
            headers: {
              Authorization: 'Bearer ' + this.header,
            },
          })
          if (res.status === 200) {
            this.$emit('getResponse', res.data)
            this.offModal()
          } else {
            alert('Data not saved')
          }
        } catch (e) {
          if (e.response && e.response.status === 401) {
            this.$router.push({ name: 'login-page' })
          } else if (e.response && e.response.status === 400) {
            alert(e.response.data.messages)
            this.names = ''
            this.dept = ''
            this.level = ''
            this.mentor = ''
            this.valid = false
            this.capacity = ''
          } else {
            console.log(e)
          }
        }
      }
    },
    async fetchSelectedMentors () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_selected_mentors',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.mentors = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
    async fetchSelectedDepartments () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_selected_departments',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.departments = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
    async fetchSelectedLevels () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_selected_levels',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.levels = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
