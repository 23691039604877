<template>
  <div style="width: 100%;">
    <v-app>
      <addClass :light="showDialog" @closeModal="close($event)" @getResponse="responseData($event)"/>
      <v-dialog
        v-model="dialogModal"
        max-width="500px"
        :persistent="true"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Change department</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-select
                    v-model="department"
                    label="select department"
                    name="Department"
                    v-validate="'required'"
                    @click="fetchSelectedDepartments"
                    :items="departments"
                  ></v-select>
                  <span style="color: red;"
                    v-show="errors.has('Department')">{{ errors.first('Department') }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="offModal"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="edit('department_id')"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="modal1"
        max-width="500px"
        :persistent="true"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Change Level for this class</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-select
                    v-model="level"
                    label="select level"
                    name="Level"
                    v-validate="'required'"
                    :items="levels"
                    @click="fetchSelectedLevels"
                  ></v-select>
                  <span style="color: red;"
                    v-show="errors.has('Levels')">{{ errors.first('Levels') }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="quitModal1"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="edit('level_id')"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="modal2"
        max-width="500px"
        :persistent="true"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Change Lecture</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-select
                    v-model="mentor"
                    label="select lecturer"
                    name="Lecturer"
                    v-validate="'required'"
                    :items="mentors"
                    @click="fetchSelectedMentors"
                  ></v-select>
                  <span style="color: red;"
                    v-show="errors.has('Lecturer')">{{ errors.first('Lecturer') }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="quitModal2"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="edit('mentor_id')"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-data-table
        :headers="headers"
        :items="classes"
        :loading="loading"
        sort-by="calories"
        class="elevation-1"
      >
        <template v-slot:item.id="{item}">
          <v-chip small :color="`#9c9c9c`" style="color: white; font-weight: 600; width: 25px; padding: 8px;">
            {{classes.map(function(x) {return x.id; }).indexOf(item.id)+1}}
          </v-chip>
        </template>
        <template v-slot:item.title="{ item }">
          <input type="text" style="width: auto;" :value="item.title" :id="'title'+item.id" @dblclick="editMode(item.id,'title')" @blur="blurMode(item.id,'title')" @keyup.enter="blurMode(item.id,'title')" readonly/>
        </template>
        <template v-slot:item.department="{ item }">
          <input type="text" style="width: 400px;" :value="item.department" :id="'department'+item.id" @dblclick="callModal(item.id,'department')"  readonly/>
        </template>
        <template v-slot:item.level="{ item }">
          <input type="text" style="width: auto;" :value="item.level" :id="'level'+item.id" @dblclick="callModal(item.id,'level')" readonly/>
        </template>
        <template v-slot:item.mentor="{ item }">
          <input type="text" style="width: auto;" :value="item.mentor" :id="'mentor'+item.id" @dblclick="callModal(item.id,'staff')" readonly/>
        </template>
        <template v-slot:item.students="{ item }">
          <router-link
            :to="{
              name:'student',
              params:{
                class_id:item.id
              }
            }"
          >
            <v-chip color="blue" text-color="white" style="cursor: pointer;">
              {{item.students}}
            </v-chip>
          </router-link>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon :color="'#0a43ae'" @click="deleteClass(item.id,item.title)">delete</v-icon>
        </template>
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>List of Classes</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="onModal"
              style="text-transform: none"
            >
              New class
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mt-2"
            @click="edit(item),state='edit'"
          >
          </v-icon>
        </template>
      </v-data-table>
    </v-app>
    <vue-confirm-dialog></vue-confirm-dialog>
  </div>
</template>
<script>
import {
  mdiAccount,
  mdiPencil,
  mdiShareVariant,
  mdiDelete,
  mdiCog,
} from '@mdi/js'
import addClass from './addClass'
import Swal from "sweetalert2"
export default {
  components: {
    addClass,
  },
  data: () => ({
    icons: {
      mdiAccount,
      mdiPencil,
      mdiShareVariant,
      mdiDelete,
      mdiCog,
    },
    headers: [
      { text: '#', value: 'id' },
      { text: 'Title', value: 'title' },
      { text: 'Department', value: 'department' },
      { text: 'Level', value: 'level' },
      { text: 'Studying Mode', value: 'mode' },
      { text: 'Mentor', value: 'mentor' },
      { text: 'Students', value: 'students' },
      { text: '', value: 'action' },
    ],
    date: new Date().toISOString().substr(0, 7),
    modal: false,
    dialogModal: false,
    logger: [],
    header: '',
    title: '',
    department: null,
    level: null,
    mentor: null,
    state: '',
    id: '',
    mentors: [],
    departments: [],
    levels: [{ text: 'Level 1', value: 1 },
      { text: 'Level 2', value: 2 },
      { text: 'Level 3', value: 3 },
      { text: 'Level 4', value: 4 },
      { text: 'Level 5', value: 5 }],
    classes: [],
    showDialog: false,
    checking: '',
    deptId: '',
    staffId: '',
    levelId: '',
    modal1: false,
    modal2: false,
    loading: false,
  }),

  computed: {
  },
  created () {
    this.fetchClass()
  },
  methods: {
    offModal () {
      this.dialogModal = false
    },
    quitModal1 () {
      this.modal1 = false
    },
    quitModal2 () {
      this.modal2 = false
    },
    editMode: function (id, key) {
      var cls = document.getElementById(key + '' + id)
      cls.focus()
      cls.readOnly = false
      cls.classList.add('editMode')
    },
    blurMode (id, key) {
      var cls = document.getElementById(key + '' + id)
      cls.readOnly = true
      cls.classList.remove('editMode')
      this.editClass(id, key, cls.value)
    },
    async editClass (id, key, value) {
      if (localStorage.getItem('logged_user')) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
      this.header = this.logger.accessToken
      const data = {
        id: id,
        key: key,
        value: value,
      }
      try {
        await this.axios({
          url: 'manipulate_class/0',
          method: 'post',
          data: data,
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
      } catch (e) {
        if (e.response && e.response.status === 403) {
          console.log(e)
        } else {
          console.log(e)
        }
      }
    },
    async fetchClass () {
      this.loading = true
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_classes',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.loading = false
          this.classes = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.loading = false
        } else {
          console.log(error)
        }
      }
    },
    close (event) {
      this.showDialog = event
    },
    onModal () {
      this.showDialog = true
    },
    responseData (event) {
      this.classes.push(event)
    },
    callModal (id, key) {
      if (key === 'department') {
        this.deptId = id
        this.dialogModal = true
      } else if (key === 'level') {
        this.levelId = id
        this.modal1 = true
      } else if (key === 'staff') {
        this.staffId = id
        this.modal2 = true
      }
    },
    async fetchSelectedMentors () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_selected_mentors',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.mentors = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
    async fetchSelectedDepartments () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_selected_departments',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.departments = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
    async fetchSelectedLevels () {
      this.loading = true
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_selected_levels',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.loading = false
          this.levels = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } if (error.response && error.response.status === 400) {
          this.loading = false
        } else {
          console.log(error)
        }
      }
    },
    async edit (key) {
      let id = 0
      let value = ''
      if (key === 'department_id') {
        id = this.deptId
        value = this.department
      } else if (key === 'level_id') {
        id = this.levelId
        value = this.level
      } else if (key === 'mentor_id') {
        id = this.staffId
        value = this.mentor
      }
      if (localStorage.getItem('logged_user')) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
      this.header = this.logger.accessToken
      const data = {
        id: id,
        key: key,
        value: value,
      }
      try {
        const response = await this.axios({
          url: 'manipulate_class/0',
          method: 'post',
          data: data,
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (response.status === 200) {
          this.fetchClass()
          if (key === 'department_id') {
            this.department = ''
            this.dialogModal = false
          } else if (key === 'level_id') {
            this.level = ''
            this.modal1 = false
          } else if (key === 'mentor_id') {
            this.mentor = ''
            this.modal2 = false
          }
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          console.log(e)
        } else {
          console.log(e)
        }
      }
    },
    deleteClass (id, title) {
      this.$confirm(
        {
          message: 'Delete ' + ' ' + title + ' ' + 'from list ?',
          button: {
            no: 'No',
            yes: 'Yes',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: async confirm => {
            if (confirm) {
              // ... do something
              if (localStorage.getItem('logged_user')) {
                this.logger = JSON.parse(localStorage.getItem('logged_user'))
              }
              this.header = this.logger.accessToken
              try {
                const res = await this.axios({
                  url: 'delete_class',
                  method: 'post',
                  data: {
                    id: id,
                  },
                  headers: {
                    Authorization: 'Bearer ' + this.header,
                  },
                })
                if (res.status === 200) {
                  this.classes = this.classes.filter(classe => classe.id !== id)
                  this.loading = false
                  this.fetchClass()
                  console.log(res.data)
                }
              } catch (error) {
                if (error.response && error.response.status === 401) {
                  this.$router.push({ name: 'login-page' })
                }
                if (error.response && error.response.status === 400) {
                  Swal.fire({
                    title: 'Error!',
                    text: error.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                  })
                  this.loading = false
                } else {
                  console.log(error)
                }
              }
            }
          },
        },
      )
    },
  },
}
</script>
<style scoped>
  .editMode {
    padding: 3px;
    background-color: #fdf9ff;
    font-size: 14px;
    border: 1px solid;
    border-radius: 3px;
  }
</style>
